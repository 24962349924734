import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import theme from '../theme';
import LanguageSwitcher from './language-switcher';
import logoImage from './logo.svg';

class Header extends Component {
    render() {
        const { siteTitle, isSticky, locale } = this.props;

        return (
            <header
                css={`
                    background: ${isSticky
                        ? theme.colors.primary
                        : 'transparent'};
                    margin-bottom: 1.45rem;
                    position: fixed;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 1;
                    transition: all 250ms;
                    padding: 0.5rem 1rem;
                    box-shadow: ${isSticky
                        ? '0 0 2px 2px rgba(0, 0, 0, 0.3)'
                        : '0 0 2px 2px rgba(0, 0, 0, 0)'};
                `}
            >
                <div
                    css={`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <h1
                        css={`
                            margin: 0;
                            font-size: 1.25rem;
                            padding: 0.25rem 0;
                            font-weight: 300;
                            text-decoration: none;
                            transition: all 250ms;
                            opacity: ${isSticky ? 1 : 0};
                            display: flex;
                            flex-grow: 1;
                            align-items: center;
                        `}
                    >
                        <div
                            css={`
                                background-image: url(${logoImage});
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                height: 2rem;
                                width: 2rem;
                                margin-right: 1rem;
                            `}
                        />
                        <span>{siteTitle}</span>
                    </h1>
                    <LanguageSwitcher locale={locale} />
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    locale: PropTypes.string,
    siteTitle: PropTypes.string,
    isSticky: PropTypes.bool,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default translate()(Header);
