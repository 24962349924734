import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { useToggle } from '@withvoid/melting-pot';

import defaultSupportedLocales from '../supported-locales';
import LanguageSwitcherMenu from './language-switcher-menu';

const LanguageSwitcher = ({
    locale,
    supportedLocales = defaultSupportedLocales,
}) => {
    const { on: isOpen, onToggle: onToggleOpen } = useToggle(false);
    const { on: isFocused, onToggle: onToggleFocus } = useToggle(false);

    const handleKeyPress = event => {
        if (event.key === 'ArrowDown' || event.key === ' ') {
            if (isFocused && !isOpen) {
                event.preventDefault();
                event.stopPropagation();
                onToggleOpen();
            }
        }
    };

    return (
        <div
            css={`
                position: relative;
            `}
        >
            <button
                css={`
                    background-color: transparent;
                    border-color: transparent;
                    box-shadow: none;
                    padding: 0.25rem 0;
                    text-transform: uppercase;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &::after {
                        display: inline-block;
                        width: 0;
                        height: 0;
                        margin-left: 0.3em;
                        vertical-align: middle;
                        content: '';
                        border-top: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-left: 0.3em solid transparent;
                    }
                `}
                aria-label="Select another language"
                aria-haspopup="true"
                aria-expanded={isOpen}
                onClick={onToggleOpen}
                onFocus={onToggleFocus}
                onBlur={onToggleFocus}
                onKeyPress={handleKeyPress}
            >
                {locale}
            </button>
            {isOpen ? (
                <LanguageSwitcherMenu
                    supportedLocales={supportedLocales}
                    onClose={onToggleOpen}
                />
            ) : null}
        </div>
    );
};

LanguageSwitcher.propTypes = {
    locale: PropTypes.string,
    supportedLocales: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
};

export default translate()(LanguageSwitcher);
