import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import Affix from './affix';
import NoSSR from 'react-no-ssr';
import Header from './header';
import theme from '../theme';

const GlobalStyle = createGlobalStyle`
    html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.15;
    }
    body {
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5;
        margin: 0;
        color: #292b2c;
    }

    * {
        box-sizing: border-box;
    }

    h2 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.1;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.1;
        margin: 1.5rem 0 0.5rem;
    }
`;

class Layout extends Component {
    offsetTop = 300;

    componentDidMount() {
        this.offsetTop = window.innerHeight - 60;
    }
    render() {
        const { children, locale } = this.props;
        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                    }
                `}
                render={data => (
                    <>
                        <GlobalStyle />
                        <NoSSR>
                            <Affix>
                                {({ affix }) => (
                                    <Header
                                        locale={locale}
                                        isSticky={affix}
                                        siteTitle={data.site.siteMetadata.title}
                                    />
                                )}
                            </Affix>
                        </NoSSR>
                        <div
                            css={`
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                            `}
                        >
                            <main
                                css={`
                                    display: flex;
                                    flex-direction: column;
                                    flex-grow: 1;
                                `}
                            >
                                {children}
                            </main>
                            <footer
                                css={`
                                    color: rgb(236, 238, 239);
                                    background-color: rgb(55, 58, 60);
                                    padding: 3em 0px;
                                `}
                            >
                                <div
                                    css={`
                                        max-width: 960px;
                                        margin: 0 auto;
                                        display: flex;
                                        justify-content: space-between;
                                        padding: 0 2em;

                                        a {
                                            color: ${theme.colors.primary};
                                            text-decoration: none;
                                        }
                                    `}
                                >
                                    <div>
                                        <div>
                                            © {new Date().getFullYear()} Les
                                            pattes noires
                                        </div>
                                        <div>
                                            <a href="mailto:contact@lespattesnoires.fr">
                                                contact@lespattesnoires.fr
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Built with{' '}
                                            <a href="https://www.gatsbyjs.org">
                                                Gatsby
                                            </a>
                                        </div>
                                        <div>
                                            Built by{' '}
                                            <a href="mailto:gildas.garcia@gmail.com">
                                                Gildas Garcia
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </>
                )}
            />
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string.isRequired,
};

export default Layout;
