/* globals window, document */

/**
 * @author Juliano Castilho <julianocomg@gmail.com>
 */

import { Component } from 'react';
import PropTypes from 'prop-types';

class Affix extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        // If undefined, default to the window height
        offset: PropTypes.number,
        // When offset is undefined, the percentage of document height
        offsetRatio: PropTypes.number,
    };

    static defaultProps = {
        offsetRatio: 0.8,
    };

    constructor() {
        super();
        this.state = {
            affix: false,
            documentHeight: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleScroll);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleScroll);
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { affix } = this.state;
        const documentHeight = window.innerHeight;
        const offset =
            this.props.offset || documentHeight * this.props.offsetRatio;

        const scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;

        if (!affix && scrollTop >= offset) {
            this.setState({
                affix: true,
                documentHeight,
            });
        }

        if (affix && scrollTop < offset) {
            this.setState({
                affix: false,
                documentHeight,
            });
        }
    };

    render() {
        const { affix } = this.state;
        const { children } = this.props;

        return children({ affix });
    }
}

export default Affix;
