import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FocusOn } from 'react-focus-on';

import theme from '../theme';

const LanguageSwitcherMenu = ({ supportedLocales, onClose }) => {
    const menu = useRef();
    const [focusedIndex, setFocusedIndex] = useState(0);

    useEffect(() => {
        if (menu.current) {
            menu.current.children[focusedIndex].children[0].focus();
        }
    }, [focusedIndex]);

    const handleKeyPress = event => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            event.stopPropagation();

            const newFocusedIndex =
                focusedIndex === supportedLocales.length - 1
                    ? 0
                    : focusedIndex + 1;

            setFocusedIndex(newFocusedIndex);
            return;
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            event.stopPropagation();

            const newFocusedIndex =
                focusedIndex === 0
                    ? supportedLocales.length - 1
                    : focusedIndex - 1;

            setFocusedIndex(newFocusedIndex);
            return;
        }
        if (event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();

            menu.current.children[focusedIndex].children[0].click();
            return;
        }
    };

    return (
        <FocusOn onClickOutside={onClose} onEscapeKey={onClose}>
            <ul
                ref={menu}
                css={`
                    background-color: white;
                    background-clip: padding-box;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    position: absolute;
                    top: 100%;
                    right: 0;
                    display: block;
                    margin: 0;
                    padding: 0.3rem 0;
                    border-radius: 0.25rem;
                    transition: all 250ms ease-in;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
                    min-width: 10rem;

                    @media (max-width: 700px) {
                        position: fixed;
                        top: 1rem;
                        bottom: 1rem;
                        left: 1rem;
                        right: 1rem;
                        font-size: 2rem;
                        padding: 1rem 0;
                    }
                `}
                onKeyDown={handleKeyPress}
            >
                {supportedLocales.map(supportedLocale => (
                    <li
                        key={supportedLocale.id}
                        value={supportedLocale.id}
                        css={`
                            list-style: none;
                            padding: 0.25rem 1.5rem;
                            text-align: left;
                            font-size: 1rem;

                            @media (max-width: 700px) {
                                padding: 1rem;
                                font-size: 2rem;
                            }
                        `}
                    >
                        <Link
                            to={`/${supportedLocale.id}`}
                            css={`
                                color: inherit;
                                text-decoration: none;
                                text-transform: none;
                                padding: 0.25rem 0.5rem;
                                transition: all 250ms ease-in;
                                display: block;

                                &:focus {
                                    background-color: ${theme.colors.primary};
                                }
                            `}
                        >
                            {supportedLocale.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </FocusOn>
    );
};

LanguageSwitcherMenu.propTypes = {
    onClose: PropTypes.func.isRequired,
    supportedLocales: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
};

export default LanguageSwitcherMenu;
